import i18n from 'i18next'
import common from './it/common.json'
import excel from './it/excel.json'
import quickAdd from './it/quickAdd.json'
import recentItems from './it/recentItems.json'
import stats from './it/stats.json'
import { initReactI18next } from 'react-i18next'

export const resources = {
  it: {
    common,
    excel,
    quickAdd,
    recentItems,
    stats
  }
} as const

i18n.use(initReactI18next).init({
  lng: 'it',
  ns: ['common', 'excel', 'quickAdd', 'recentItems', 'stats'],
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
})
