import axios from 'axios'
import { VersionData } from 'interfaces/models'
import { baseApiUrl } from 'index'

const versionApi = () => ({
  async getVersionData() {
    return axios.get<VersionData>(baseApiUrl + '/version').then((res) => res.data)
  }
})

export default versionApi()
