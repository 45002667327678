import styled from '@emotion/styled'
import { Card } from '@mui/material'

const ColorCard = styled(Card)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  marginBottom: '20px',
  marginTop: '20px',
  paddingBottom: '20px'
})

export default ColorCard
