import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Project } from 'interfaces/models'

export const ProjectStore = createSlice({
  initialState: {
    data: undefined as Project[] | undefined
  },
  name: 'client',
  reducers: {
    setProjectsSuccess(state, action: PayloadAction<Project[]>) {
      state.data = action.payload
    }
  }
})

export const { setProjectsSuccess } = ProjectStore.actions
