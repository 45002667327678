import React from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  Paper,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
  Typography
} from '@mui/material'
import theme from '../theme/theme'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import ReplayIcon from '@mui/icons-material/Replay'
import Alert from '@mui/material/Alert'

const ErrorPage = ({
  error,
  resetErrorBoundary,
  serializedError
}: {
  error: { message: string }
  resetErrorBoundary: () => void
  serializedError?: string
}) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />

        <Container component="main" maxWidth="sm">
          <Card style={{ backgroundColor: '#333333', marginTop: 20 }}>
            <CardHeader
              avatar={<ReportProblemIcon />}
              title={"Errore dell'applicazione"}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              <Alert severity="error" sx={{ width: '100%' }}>
                {error.message}
              </Alert>

              {serializedError && (
                <Paper sx={{ marginTop: '20px', padding: '10px' }}>
                  <Typography variant="body2">{serializedError}</Typography>
                </Paper>
              )}

              <Stack direction="row" spacing={2} sx={{ marginTop: '20px' }}>
                <Button
                  color="primary"
                  startIcon={<ReplayIcon />}
                  variant="contained"
                  onClick={resetErrorBoundary}>
                  {'Riprova'}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default ErrorPage
