import React, { lazy, Suspense, useEffect, useState } from 'react'
import AppLoader from './loader'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useAppDispatch } from '../lib/useAppDispatch'
import { setVersionDataSuccess } from 'store/settings/settings.store'
import { VersionData } from '../interfaces/models'

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'))

const App: React.FC<{ versionData: VersionData }> = ({ versionData }) => {
  const [tokenLoaded, setTokenLoaded] = useState(false)
  const { isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0()
  const dispatch = useAppDispatch()

  dispatch(setVersionDataSuccess(versionData))

  useEffect(() => {
    if (isAuthenticated && !tokenLoaded) {
      getAccessTokenSilently().then((token) => {
        axios.defaults.headers.Authorization = `Bearer ${token}`
        setTokenLoaded(true)
      })
    }
  }, [isAuthenticated, tokenLoaded, getAccessTokenSilently])

  if (isLoading) return <AppLoader />

  if (isAuthenticated) {
    return !tokenLoaded ? (
      <AppLoader />
    ) : (
      <Suspense fallback={<AppLoader />}>
        <AuthenticatedApp />
      </Suspense>
    )
  }

  loginWithRedirect({ redirectUri: window.location.href })
}

export default App
