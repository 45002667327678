import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Movement } from 'interfaces/models'

interface MovementRow {
  category?: string
  page: number
  data: Movement[]
}

interface MovementStore {
  data?: MovementRow[]
  current?: Movement
}

const initialState: MovementStore = {}

export const MovementStore = createSlice({
  initialState,
  name: 'client',
  reducers: {
    setLastItemsSuccess(state, action: PayloadAction<MovementRow>) {
      state.data = [
        ...(state.data ?? []).filter(
          (row) => !(row.page === action.payload.page && row.category === action.payload.category)
        ),
        action.payload
      ]
    },
    setCurrentItem(state, action: PayloadAction<Movement | undefined>) {
      state.current = action.payload
    },
    resetLastItems(state) {
      state.data = initialState.data
      state.current = initialState.current
    }
  }
})

export const { setLastItemsSuccess, setCurrentItem, resetLastItems } = MovementStore.actions
