import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Category } from 'interfaces/models'

export const CategoryStore = createSlice({
  initialState: {
    data: undefined as Category[] | undefined
  },
  name: 'client',
  reducers: {
    setCategoriesSuccess(state, action: PayloadAction<Category[] | undefined>) {
      state.data = action.payload
    }
  }
})

export const { setCategoriesSuccess } = CategoryStore.actions
