import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import { CategoryStore } from './store/category/category.store'
import { httpStatusStore } from './store/http-status/http-status.store'
import { SettingsStore } from './store/settings/settings.store'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { ProjectStore } from './store/project/project.store'
import { NoteStore } from './store/note/note.store'
import { MovementStore } from './store/movement/movement.store'
import theme from 'theme/theme'
import { Container, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from './components/ErrorPage'
import versionApi from './api/versionApi'

const rootReducer = combineReducers({
  category: CategoryStore.reducer,
  httpStatus: httpStatusStore.reducer,
  movement: MovementStore.reducer,
  note: NoteStore.reducer,
  project: ProjectStore.reducer,
  settings: SettingsStore.reducer
})

export const baseApiUrl = process.env.REACT_APP_BACKEND_URL
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer // se è in produzione non include i devtools
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

versionApi
  .getVersionData()
  .then((versionData) => {
    root.render(
      <ErrorBoundary fallbackRender={ErrorPage}>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          audience={auth0Audience}
          redirectUri={window.location.origin}
          scope={'openid profile email'}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <CssBaseline />

                <Container component="main" maxWidth="sm">
                  <App versionData={versionData} />
                </Container>
              </StyledEngineProvider>
            </ThemeProvider>
          </Provider>
        </Auth0Provider>
      </ErrorBoundary>
    )
  })
  .catch((error) => {
    root.render(
      <ErrorPage
        error={error}
        serializedError={JSON.stringify(error)}
        resetErrorBoundary={() => window.location.reload()}
      />
    )
  })
