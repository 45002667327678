import React from 'react'
import ColorCard from 'components/template/ColorCard'
import Loader from './Loader'

const AppLoader: React.FC = () => {
  return (
    <ColorCard>
      <Loader />
    </ColorCard>
  )
}

export default AppLoader
