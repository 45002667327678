import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VersionData } from 'interfaces/models'

export const SettingsStore = createSlice({
  initialState: {
    error: undefined as string | undefined,
    hideValues: false,
    processing: false,
    version: 0,
    versionData: undefined as VersionData | undefined
  },
  name: 'client',
  reducers: {
    setErrorSuccess(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload
    },
    setHideValuesSuccess(state, action: PayloadAction<boolean>) {
      state.hideValues = action.payload
    },
    setProcessingSuccess(state, action: PayloadAction<boolean>) {
      state.processing = action.payload
    },
    setVersionDataSuccess(state, action: PayloadAction<VersionData>) {
      state.versionData = action.payload
    },
    incrementVersion(state) {
      state.version = state.version + 1
    }
  }
})

export const {
  setErrorSuccess,
  setHideValuesSuccess,
  setProcessingSuccess,
  incrementVersion,
  setVersionDataSuccess
} = SettingsStore.actions
